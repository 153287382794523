<template>
  <div class="binding_box">
    <div class="binding_main">
      <div class="erweima" @click="updata_qecode">
        <i class="iconfont icon-saoma"></i>
      </div>

      <div class="txt_box">扫描绑定设备的二维码</div>

      <van-button class="binding_btn" round block @click="updata_list">
        更新绑定
      </van-button>

      <van-button class="binding_btn" round block @click="logout">
        退出登录
      </van-button>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "@/api/user";
// import { postData } from "@/api/user";
import { getAdminTokens, _debounce, getUrlParam } from "@/libs/util";
import { setAdminTokens, setTokens } from "@/libs/util";

import wx from "weixin-js-sdk";

export default {
  data() {
    return {};
  },
  created() {
    let token = getAdminTokens();
    if (!token) {
      this.$router.push({ name: "admin_login" });
    }
  },
  methods: {
    onSubmit() {
      postData({
        url: `api/maintain/login`,
        data: {
          phone: this.phone,
          pass_word: this.password,
        },
      }).then((res) => {
        let data = res.data;
        console.log(data);
      });
    },
    // 更新绑定
    updata_qecode: _debounce(function () {
      //   this.$router.push({ name: "admin_equipment_msg" });
      let that = this;

      getData({
        url: "api/get_js_config",
        data: {
          url: window.location.href.split("#")[0],
        },
      }).then((res) => {
        console.log(res);
        let data = res.data.data;
        wx.config({
          debug: false,
          appId: data.appId + "",
          timestamp: data.timestamp,
          nonceStr: data.nonceStr + "",
          signature: data.signature + "",
          jsApiList: ["scanQRCode", "checkJsApi"], // 必填,需要使用的JS接口列表
        });
        wx.error(function (res) {
          alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
        });

        wx.ready(function () {
          // config信息验证成功后会执行ready方法,所有接口调用都必须在config接口获得结果之后
          // config 是一个客户端的异步操作,所以如果需要在页面加载时调用相关接口,则须把相关接口放在ready函数中调用来确保正确执行.对于用户触发是才调用的接口,则可以直接调用,不需要放在ready函数中
          wx.checkJsApi({
            // 判断当前客户端版本是否支持指定JS接口
            jsApiList: ["scanQRCode"],
            success: function (res) {
              // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
              if (res.checkResult.scanQRCode === true) {
                wx.scanQRCode({
                  // 微信扫一扫接口
                  desc: "scanQRCode desc",
                  needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                  scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                  success: function (res) {
                    console.log(res);
                    let result = res.resultStr.split(",") + "";
                    let url = result.split("?")[1] + "";

                    // var reg = new RegExp("(^|&)" + "id" + "=([^&]*)(&|$)");
                    // var r = url.match(reg);
                    // let id = r[2];

                    let id = getUrlParam(url, "id");

                    getData({
                      url: "api/charging/check_qr_code",
                      data: {
                        id,
                      },
                    }).then((res) => {
                      console.log(res);
                      if (res.data.code === 200) {
                        // this.$router.push({ name: "admin_equipment_msg" });

                        that.$router.push({
                          name: "admin_equipment_msg",
                          query: {
                            qr_id: id,
                          },
                        });
                      } else if (res.data.code === 422) {
                        alert(res.data.data.msg);
                      }
                    });
                  },
                });
              } else {
                alert("抱歉，当前客户端版本不支持扫一扫");
              }
            },
            fail: function (res) {
              // 检测getNetworkType该功能失败时处理
              alert("检测getNetworkType该功能失败" + res);
            },
          });
        });
        /* 处理失败验证 */
        wx.error(function (res) {
          // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
          alert("配置验证失败: " + res.errMsg);
        });
      });
      // }, 200);
    }),
    updata_list() {
      this.$router.push({ name: "admin_binding_list" });
    },
    logout() {
      setAdminTokens("");
      setTokens("");

      this.$router.push({ name: "admin_login" });
    },
  },

  beforeRouteEnter(to, from, next) {
    var u = navigator.userAgent;

    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    // XXX: 修复iOS版微信HTML5 History兼容性问题

    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace

      location.assign(to.fullPath);
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
.binding_box {
  height: 100vh;
  background-color: #fff !important;
  text-align: center;
}

.binding_main {
  padding-top: 190px;

  .erweima {
    margin: 0 auto 13px;
    width: 161px;
    height: 161px;
    line-height: 161px;
    background: linear-gradient(180deg, #6ff4bc 0%, #0aaf6d 100%);
    border-radius: 50%;
    text-align: center;

    .iconfont {
      color: #fff;
      font-size: 59px;
    }
  }

  .txt_box {
    font-size: 14px;
    color: #000000;
    line-height: 1;
  }

  .binding_btn {
    margin: 69px auto 0;
    width: 93px;
    height: 29px;
    font-size: 14px;
    color: #3dc48d;
    border: 1px solid #01bc84;
    background-color: #fff;
    border-radius: 15px;
  }
}
</style>